/* RESETS */
html,
body {
  margin: 0;

  /*
    While not totally recommended due to browser support, these font-smoothing settings are used
    widely at sites such as vercel.com to ensure fonts look the same in design as they do in
    the final implementation
  */
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-weight: unset;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
