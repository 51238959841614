@import 'styles/variables.scss';

// NOTE: This file does not end in .module so that it can apply this style globally to the intercom
// widget across all pages

// Update the intercom z-index so that drawers will appear over it, intercom by default applies
// Some styles so that it's at the top z-index (which we don't want here)
.intercom-lightweight-app {
  z-index: $z-index-chat !important;
}
